<template>
    <div>
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h1 class="h3 mb-0 text-gray-800">{{product.title}}</h1>
                <span>
                    <button href="#modalDelete" data-toggle="modal" class="btn btn-sm btn-info shadow-sm trigger-btn"><i class="fa fa-edit fa-sm text-white-50"></i>Mark out of stock</button>
                    &nbsp;
                    <button href="#modalDelete" data-toggle="modal" class="btn btn-sm btn-danger shadow-sm trigger-btn"><i class="fa fa-trash fa-sm text-white-50"></i> Delete {{product.title}}</button>
                    &nbsp;
                    <router-link :to="`/panel/products/${product.id}/edit`" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fa fa-edit fa-sm text-white-50"></i> Edit Product</router-link>
                </span>
            </div>
        </div>
       <!--  <div id="chart">
            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div> -->
         <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h3 class="h3 mb-0 text-gray-800">{{product.title}} Information</h3>
                
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Title</label>
                    <input type="text"   v-model="product.title" class="form-control" id="inputEmail4" placeholder="">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Price</label>
                    <input type="text"   v-model="product.price" class="form-control" id="inputEmail4" placeholder="">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Downloads</label>
                    <input type="text"   v-model="product.downloads" class="form-control" id="inputEmail4" placeholder="">
                </div>
              
                
                    
            </div>
            <div class="form-row">
                
                <div class="col-md-6">
                    <label for="inputEmail4">Categories</label>
                     <multiselect
                      v-model="product.categories"
                      :options="categories"
                      :multiple="true"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      class="form-control"
                      placeholder="Pick category"
                      label="name"
                      track-by="id"
                    ></multiselect>
                </div>
                <div class="col-md-6">
                    <label for="inputEmail4">Tags</label>
                     <multiselect
                      v-model="product.tags"
                      :options="tags"
                      :multiple="true"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      class="form-control"
                      placeholder="Pick category"
                      label="name"
                      track-by="id"
                    ></multiselect>
                </div>
                
                    
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="inputEmail4">Experience</label>
                    <textarea class="form-control" name="" id="" cols="30" rows="10">
                        {{product.description}}
                    </textarea>
                </div>
            </div>
        </div>
        
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h1 class="h3 mb-0 text-gray-800">Stock Photo Activities</h1>
            </div>
            <p class="mb-4">View all your activities and analysis on your product: <b>[{{product.title}}]</b>.</p>
        </div>
        <div class="empty-products" style="text-align:center;padding: 70px 0 ">
            <div class="empty-img">

                

                <svg id="Flat" height="80px" viewBox="0 0 512 512" width="80px" xmlns="http://www.w3.org/2000/svg"><path d="m280 192a8 8 0 0 1 -8-8v-80a56 56 0 0 0 -112 0v80a8 8 0 0 1 -16 0v-80a72 72 0 0 1 144 0v80a8 8 0 0 1 -8 8z" fill="#dadcde"/><path d="m296 472h-272l16-320h240z" fill="#eb423f"/><path d="m360 472h-64l-16-320h64z" fill="#d13330"/><path d="m328.47 431.99c-.16.01-.32.01-.48.01a8 8 0 0 1 -7.98-7.53l-16-272c-.01-.16-.01-.31-.01-.47h16.02l15.97 271.53a8.007 8.007 0 0 1 -7.52 8.46z" fill="#a82a27"/><path d="m488 472h-64l-1-12.93v-.01l-15-195.06h64l14.75 191.69v.01z" fill="#eba72e"/><path d="m456.8 431.96a7.322 7.322 0 0 1 -.81.04 8 8 0 0 1 -7.95-7.2l-.29-2.86-15.71-157.14a7.143 7.143 0 0 1 -.04-.8h16.04l15.72 157.21.2 1.99a8 8 0 0 1 -7.16 8.76z" fill="#e09f2c"/><path d="m488 472h-9.62l-22.38-33.58-22.38 33.58h-9.62l-1-12.93v-.01l24.75-37.12 1.59-2.38a8.01 8.01 0 0 1 13.32 0l1.1 1.65 22.99 34.48v.01z" fill="#e09f2c"/><path d="m424 472h-208l16-208h176z" fill="#f7b030"/><path d="m328 416h-16a40.045 40.045 0 0 1 -40-40v-64a8 8 0 0 1 16 0v64a24.027 24.027 0 0 0 24 24h16a24.027 24.027 0 0 0 24-24v-64a8 8 0 0 1 16 0v64a40.045 40.045 0 0 1 -40 40z" fill="#e9eef2"/><path d="m96 184h16v48h-16z" fill="#dadcde"/><path d="m104 192a8 8 0 0 1 -8-8v-80a72 72 0 0 1 144 0v80a8 8 0 0 1 -16 0v-80a56 56 0 0 0 -112 0v80a8 8 0 0 1 -8 8z" fill="#e9eef2"/><path d="m128 296h-48v-64l24-16 24 16z" fill="#f7b030"/></svg>
            
            </div>
            <p style="color:grey;font-size:14px; padding-top:10px">
                You don't have any activities yet
            </p>
        </div>
       
        <base-delete-modal @deleteItem="deleteProduct" :name="product.title"/>
    </div>
</template>

<script>
    import Crud from '../../services/main/CRUD.js'
    import VueApexCharts from 'vue-apexcharts'
    import BaseDeleteModal from '../../components/BaseDeleteModal.vue'
    import Multiselect from "vue-multiselect";
    export default {
        data() {
            return {
                id: this.$route.params.id,
                product: {
                    title: "",
                },
                categories: [],
                tags: []
          
          
            }
        },
        components: {
            apexchart: VueApexCharts,
            BaseDeleteModal,
            Multiselect
        },
        methods: {
            
            deleteProduct() {
                this.$store.dispatch('setLoading',true)
                Crud.delete('partners/products',this.product.id)
                .then((res) => {
                    console.log(res)
                    this.$store.dispatch('setLoading',false)
                    this.$store.dispatch('success',`${this.product.title} deleted`)
                    this.$router.go(-1)
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.dispatch('setLoading',false)
                })

            },
            async getProduct() {
                this.$store.dispatch('setLoading',true)
                try {
                    var product = await Crud.show('admin/photos', this.id)
                    //var categories =await Crud.index('admin/categories');
                    this.categories = product.categories
                    this.tags = product.tags
                    //this.categories = response;
                    //console.log(product)
                    this.product = product.data
                    console.log(this.product)
                
                    this.$store.dispatch('setLoading',false)
                   
                } catch (e) {
                    this.$store.dispatch('setLoading',false)

                }
            },
            route(path) {
                this.$router.push(path)
            },
        },
        created() {
            this.getProduct()
            //this.$store.dispatch('setLoading',true)

        }
    }
</script>

<style lang="scss" scoped>

</style>